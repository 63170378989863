import React from "react";
import Helmet from "react-helmet";
import usePortfolioIndex from '../hooks/usePortfolioIndex';
import useSiteMetadata from '../hooks/useSiteMetadata';

const HelmetComponent = ({ postMeta = {}, postPath = "", postSEO = false }) => {
  const { siteUrl, siteLogo, title: siteTitle, description: siteDescription } = useSiteMetadata()

  let title
  let description
  let image
  let portfolioURL

  const pathPrefix = `/`
  const realPrefix = pathPrefix === "/" ? "" : pathPrefix;

  const indexData = usePortfolioIndex()
  const { frontmatter = {} } = indexData.markdownRemark
  const { index, sharingImage } = frontmatter
  if (sharingImage.length > 0 && sharingImage[0].url) {
    image = `${sharingImage[0].url}-/stretch/off/-/resize/x1600/${sharingImage[0].name}`
  }

  if (postSEO) {

    const { portfolioTitle, subtitle, year } = postMeta
    title = `${portfolioTitle} | ${siteTitle}`

    const constructDesc = () => {
      let result
      if (portfolioTitle) {
        result = portfolioTitle
      }
      if (subtitle) {
        result = result + ` ${subtitle}`
      }
      if (year) {
        result = result + `, ${year}`
      }
      return result
    }

    description = postMeta.description && postMeta.description.length > 2 ? postMeta.description : constructDesc()
    const indexItem = index.find(item => {
      return postPath === `/portfolios/${item.project?.frontmatter?.slug}/`
    })
    if (indexItem) {
      const { thumbnail } = indexItem
      image = `${thumbnail[0].url}-/stretch/off/-/resize/x1600/${thumbnail[0].name}`
    }
    portfolioURL = siteUrl + realPrefix + postPath
  } else {
    // default or global title
    title = siteTitle;
    description = siteDescription
    //keywords = config.meta.keywords
    image = image || siteLogo
  }

  // url for schema.org
  const rootURL = siteUrl + pathPrefix;

  // schema.org
  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: rootURL,
      name: title,
      //alternateName: config.siteTitleAlt ? config.siteTitleAlt : ""
    }
  ];

  if (postSEO) {
    schemaOrgJSONLD.push(
      {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": portfolioURL,
              name: title,
              image
            }
          }
        ]
      },
      {
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        url: rootURL,
        name: title,
        //alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
        headline: title,
        image: {
          "@type": "ImageObject",
          url: image
        },
        description
      }
    );
  }

  return (
    <Helmet>
      {/* General tags */}
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
      {/*<meta name="keywords" content={keywords} />*/}
      <meta name="image" content={image} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={postSEO ? portfolioURL : rootURL} />
      <meta property="og:type" content="business.business" />
      {postSEO ? <meta property="og:type" content="article" /> : null}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <meta name="apple-mobile-web-app-capable" content="yes" />
    </Helmet>
  )
}

export default HelmetComponent
